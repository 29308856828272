import axios from 'axios'
import lodash from 'lodash'
import moment from 'moment-timezone'
window.axios = axios
window._ = lodash
window.moment = moment
moment.tz.setDefault("Asia/Tokyo")
//
import Vue from 'vue'
import App from './App.vue'
import router from './router'
//import store from './store'

Vue.config.productionTip = false

new Vue({
  router,
  //store,
  render: h => h(App)
}).$mount('#app')
