<style lang="scss" scoped>
#loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.container {
  width: 640px;
  margin: 0 auto;
}
.fixed-header {
  position: sticky;
  top: 0;
  background-color: white;
}
.hotel-name {
  padding: 24px;
  font-weight: bold;
  font-size: 1.2rem;
}
.divider {
  border-top: 1px solid #ccc;
}
.info {
  display: flex;
  align-items: center;
  padding: 24px;
  .review-count {
    flex-grow: 1;
  }
  .point {
    padding: 0 1rem;
    text-align: center;
    font-weight: bold;
    header {
      font-size: 2rem;
    }
    footer {
      font-size: 10px;
    }
  }
  .reputation {
    color: orange;
  }
  .good-rate {
    color: #08f;
    border-left: 1px dashed #aaa;
  }
}
.review {
  padding: 24px;
  background-color: white;
  border-bottom: 1px dashed #ccc;
}
.footer {
  position: sticky;
  bottom: 0;
  padding: 0.5rem;
  font-size: 10px;
  color: #aaa;
  background-color: #eee;
  text-align: center;
}
a.btn,
button.btn {
  cursor: pointer;
  text-decoration: none;
  color: #aaa;
  font-weight: bold;
}
</style>

<template>
  <section id="loading" v-if="isLoading">読み込み中</section>
  <section class="container" v-else>
    <header class="fixed-header">
      <div class="hotel-name">
        {{ hotelName }}
      </div>
      <div class="divider"></div>
      <section class="info">
        <section class="review-count">
          <div style="color: #999999">
            {{ reviewCount }} 件のクチコミ（{{ updatedAt.slice(0, 10) }} 更新）
          </div>
        </section>
        <!-- <section class="point reputation">
          <header>
            ★
            {{reputation}}
          </header>
          <footer>
            １０点満点中
          </footer>
        </section> -->
        <!-- <section v-if="true" class="point good-rate">
          <header>
             <span style="font-size: 1rem">％</span>Ï
          </header>
          <footer>
            高評価率
          </footer>
        </section> -->
      </section>
      <div class="divider"></div>
    </header>
    <section>
      <article class="review" v-for="(item, index) in reviews">
        <header style="display: flex; align-items: center">
          <div
            v-bind:style="{
              backgroundColor: item.siteColor,
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              height: '20px',
              width: '100px',
              fontSize: '10px',
              justifyContent: 'center',
              float: 'left',
              marginRight: '10px',
            }"
          >
            {{ item.siteName }}
          </div>
          &nbsp;
          <div
            v-bind:style="{
              color: item.siteColor,
              fontSize: '2rem',
              float: 'left',
              marginRight: '10px',
              alignItems: 'center',
            }"
          >
            <b v-if="item.siteID != 'survey'">{{ item.totalOriginalScore }}</b>
          </div>
          <!-- <div style="float: left;margin-right: 10px;color: #AAA;size: 8px;"> 元の点数</div> -->
          <!-- <div  v-bind:style="{ color:item.siteColor , fontSize: '1rem' ,float:'left'} "><b>({{item.totalOriginalScore}})</b></div> -->
          <div style="color: #aaa; margin-left: auto">
            投稿日 : {{ item.postDate }}
          </div>
          <br /><br />
        </header>
        <div>
          <div
            v-if="item.truncate"
            style="
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            "
          >
            {{ item.comment }}
          </div>
          <div v-else>
            {{ item.comment }}
          </div>
        </div>
        <footer align="right">
          <template v-if="item.siteID == 'survey'">
            <a class="btn" @click="item.truncate = !item.truncate">
              <template v-if="item.truncate"> > クチコミ確認 </template>
              <template v-else> 閉じる </template>
            </a>
          </template>
          <template v-else>
            <a class="btn" :href="item.getUrl" target="_blank">
              > <span style="text-decoration: underline">クチコミ確認</span>
            </a>
          </template>
        </footer>
      </article>
    </section>
    <footer class="footer">Powered by RepChecker</footer>
  </section>
</template>

<script>
export default {
  async created() {
    await this.getData()
  },
  watch: {
    hotelId() {
      this.getData()
    },
  },

  data: () => ({
    data: {},
    isLoading: true,
  }),
  computed: {
    hotelId() {
      return this.$route.params.hotelId
    },
    hotelName() {
      return this.data.hotelName
    },
    reputation() {
      return this.data.reputation
    },
    testColor() {
      return '#666666'
    },
    reviewCount() {
      return this.data.reviewCount
    },

    goodRate() {
      return this.data.goodRate
    },

    updatedAt() {
      return this.data.updatedAt
    },
    reviews() {
			return this.data.reviews
			// 緑水亭の暫定対応: 掲載するクチコミを選択できない -> 設定コメントアウト済み（不明） -> バッチ修正が必要
				.filter(review => !review.comment.startsWith('この件は掲載不要です。'))
    },
  },
  methods: {
    async getData() {
      this.isLoading = true
      try {
        const baseUrl = process.env.VUE_APP_BASE_URL
        const url = `${baseUrl}/${this.hotelId}`

        const { data } = await axios.get(url)
        data.reviews = data.reviews.map((review) => {
          //OTAの規約により全文表示は違反になる。 + メモリ削減
          if (review.siteID != 'survey') {
            // review.comment = _.truncate(review.comment, { length: 35 })
            review.comment = review.comment.slice(0, 64) //1行35程度
          }
          review.truncate = true //surveyの場合のコメント開閉用プロパティ
          return review
        })
        this.data = data

        document.title = `${data.hotelName} | RepChecker` //タブのタイトルを設定
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
