var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isLoading)?_c('section',{attrs:{"id":"loading"}},[_vm._v("読み込み中")]):_c('section',{staticClass:"container"},[_c('header',{staticClass:"fixed-header"},[_c('div',{staticClass:"hotel-name"},[_vm._v(" "+_vm._s(_vm.hotelName)+" ")]),_c('div',{staticClass:"divider"}),_c('section',{staticClass:"info"},[_c('section',{staticClass:"review-count"},[_c('div',{staticStyle:{"color":"#999999"}},[_vm._v(" "+_vm._s(_vm.reviewCount)+" 件のクチコミ（"+_vm._s(_vm.updatedAt.slice(0, 10))+" 更新） ")])])]),_c('div',{staticClass:"divider"})]),_c('section',_vm._l((_vm.reviews),function(item,index){return _c('article',{staticClass:"review"},[_c('header',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{style:({
            backgroundColor: item.siteColor,
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            height: '20px',
            width: '100px',
            fontSize: '10px',
            justifyContent: 'center',
            float: 'left',
            marginRight: '10px',
          })},[_vm._v(" "+_vm._s(item.siteName)+" ")]),_vm._v("   "),_c('div',{style:({
            color: item.siteColor,
            fontSize: '2rem',
            float: 'left',
            marginRight: '10px',
            alignItems: 'center',
          })},[(item.siteID != 'survey')?_c('b',[_vm._v(_vm._s(item.totalOriginalScore))]):_vm._e()]),_c('div',{staticStyle:{"color":"#aaa","margin-left":"auto"}},[_vm._v(" 投稿日 : "+_vm._s(item.postDate)+" ")]),_c('br'),_c('br')]),_c('div',[(item.truncate)?_c('div',{staticStyle:{"overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.comment)+" ")]):_c('div',[_vm._v(" "+_vm._s(item.comment)+" ")])]),_c('footer',{attrs:{"align":"right"}},[(item.siteID == 'survey')?[_c('a',{staticClass:"btn",on:{"click":function($event){item.truncate = !item.truncate}}},[(item.truncate)?[_vm._v(" > クチコミ確認 ")]:[_vm._v(" 閉じる ")]],2)]:[_c('a',{staticClass:"btn",attrs:{"href":item.getUrl,"target":"_blank"}},[_vm._v(" > "),_c('span',{staticStyle:{"text-decoration":"underline"}},[_vm._v("クチコミ確認")])])]],2)])}),0),_c('footer',{staticClass:"footer"},[_vm._v("Powered by RepChecker")])])
}
var staticRenderFns = []

export { render, staticRenderFns }